import { Fragment, useState } from "react";
import { Alert, App, Button, DatePicker, Form, Input, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { submitEformApi } from "../../services/api.service";
import { getSessionStorage, saveSessionStorage } from '../../services/jwt.service';
import { ArrowLeftIcon } from "../../components/SvgIcon";
import JobTypeJson from "../../assets/json/jobType.json";
import BRIQueLogo from "../../assets/images/brique-logo-image.png";
import { allowedInputNumberOnly, allowedInputTextOnly } from "../../utils/helper";

const { TextArea } = Input;

function OpenSavingAccount() {
  const navigate = useNavigate();
  const { index } = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const formIdx = index ? index - 1 : 0;
  const transactionsForm = getSessionStorage("transactions_form") || [];
  const transactionDetail = transactionsForm[formIdx] || {}

  const onUpdateEforms = () => {
    transactionsForm[formIdx].filled = true;
    saveSessionStorage("transactions_form", transactionsForm);
  }

  const onFinish = async (values) => {
    try {
      const request = {
        prefix: transactionDetail.code,
        data: values
      }
      setLoading(true);
      const response = await submitEformApi(request);
      if (response?.status != 200) throw response?.message;

      setLoading(false);
      onUpdateEforms();
      navigate(-1);
    } catch (error) {
      setLoading(false);
      message.error(error);
      console.log("Error onSubmitEform:", error)
    }
  }

  return (
    <div className="w-screen h-screen">
      <div className="w-full flex items-center gap-1 p-12">
        <div className="flex mr-4 cursor-pointer" onClick={() => navigate(-1)}>
          <ArrowLeftIcon />
        </div>
        <div className="flex max-w-[30px]">
          <img src={BRIQueLogo} alt="" />
        </div>
        <div className="text-white text-2xl font-prompt font-medium">
          BRIQue
        </div>
      </div>
      <div className="relative h-[calc(100%-128px)] overflow-y-auto bg-[#F9FAFB] rounded-t-3xl px-14 py-12">
        <Alert message="Form hanya bisa di isi sekali." type="info" showIcon />
        <div className="text-[#212B36] text-2xl font-semibold my-4">{transactionDetail?.label}</div>
        <div className="text-[#637381] text-sm mb-4">Isi formulir dibawah ini dengan teliti.</div>
        <Form
          size="small"
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
          initialValues={{ isRekening: true, tanggalLahir: dayjs().subtract(17, 'year') }}
          validateMessages={{
            required: '${label} is required!',
            string: {
              min: 'Input must be minimum ${min} character!',
              range: 'Input minimum & maximum must be ${min} character!'
            }
          }}
          onFinish={onFinish}
        >
          <div className="grid grid-cols-[repeat(2,_1fr)]">
            <Form.Item className="form-item" label="Apakah anda sudah memiki rekening BRI?" name="isRekening" rules={[{ required: true }]}>
              <Select
                size="large"
                placeholder="Apakah anda sudah memiki rekening BRI?"
                popupClassName="form-item-select"
                options={[
                  { value: true, label: 'Sudah Memiliki Rekening' },
                  { value: false, label: 'Tidak Memiliki Rekening' }
                ]}
              />
            </Form.Item>
            <Form.Item className="form-item" label="Nomor Identitas" name="nomorIdentitas" normalize={allowedInputNumberOnly} rules={[{ required: true }]}>
              <Input size="large" placeholder="Nomor Identitas" maxLength={20} showCount />
            </Form.Item>
            <Form.Item className="form-item" label="Nama Nasabah" name="namaPemilikRekeningAsal" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
              <Input size="large" placeholder="Nama Nasabah" maxLength={80} />
            </Form.Item>
            <Form.Item className="form-item" label="Tanggal Lahir" name="tanggalLahir" rules={[{ required: true }]}>
              <DatePicker
                size="large"
                inputReadOnly
                format="DD MMMM YYYY"
                placeholder="Tanggal Lahir"
                style={{ width: '100%' }}
                popupStyle={{ width: '100%' }}
                getPopupContainer={trigger => trigger.parentElement}
                showToday={false}
                disabledDate={(current) => current && current > dayjs().endOf('day')}
              />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.isRekening !== next.isRekening}>
              {({ getFieldValue }) => !getFieldValue('isRekening') && (
                <Fragment>
                  <Form.Item className="form-item" label="Nama Gadis Ibu Kandung" name="namaIbuKandung" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Nama Gadis Ibu Kandung" maxLength={80} />
                  </Form.Item>
                  <Form.Item className="form-item" label="Nomor Telepon" name="nomorTelepon" normalize={allowedInputNumberOnly} rules={[{ required: true, min: 9 }]}>
                    <Input size="large" placeholder="Nomor Telepon" maxLength={13} />
                  </Form.Item>
                  <Form.Item className="form-item" label="Email" name="email" rules={[{ required: true, type: 'email' }]}>
                    <Input size="large" placeholder="Email" maxLength={40} />
                  </Form.Item>
                  <Form.Item className="form-item" label="Alamat Sesuai Identitas" name="alamatSesuaiIdentitas" rules={[{ required: true }]}>
                    <TextArea size="large" placeholder="Alamat Sesuai Identitas" maxLength={80} autoSize />
                  </Form.Item>
                  <Form.Item className="form-item" label="Provinsi" name="provinsi" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Provinsi" maxLength={80} />
                  </Form.Item>
                  <Form.Item className="form-item" label="Kabupaten/Kota" name="kabupatenKota" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Kabupaten/Kota" maxLength={80} />
                  </Form.Item>
                  <Form.Item className="form-item" label="Kecamatan" name="kecamatan" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Kecamatan" maxLength={80} />
                  </Form.Item>
                  <Form.Item className="form-item" label="Kelurahan" name="kelurahan" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Kelurahan" maxLength={80} />
                  </Form.Item>
                  <Form.Item className="form-item" label="RT" name="rt" normalize={allowedInputNumberOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="RT" maxLength={3} showCount />
                  </Form.Item>
                  <Form.Item className="form-item" label="RW" name="rw" normalize={allowedInputNumberOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="RW" maxLength={3} showCount />
                  </Form.Item>
                  <Form.Item className="form-item" label="Kode Pos" name="kodePos" normalize={allowedInputNumberOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Kode Pos" maxLength={5} showCount />
                  </Form.Item>
                  <Form.Item className="form-item" label="Data Alamat Domisili" name="alamatSesuaiDomisili" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      placeholder="Data Alamat Domisili"
                      popupClassName="form-item-select"
                      options={[
                        { value: true, label: 'Sesuai Alamat Identitas' },
                        { value: false, label: 'Tidak Sesuai Alamat Identitas' }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate={(prev, next) => prev.alamatSesuaiDomisili !== next.alamatSesuaiDomisili}>
                    {({ getFieldValue }) => !getFieldValue('alamatSesuaiDomisili') && (
                      <Fragment>
                        <Form.Item className="form-item" label="Alamat Domisili" name="alamatDomisili" rules={[{ required: true }]}>
                          <TextArea size="large" placeholder="Alamat Domisili" maxLength={80} autoSize />
                        </Form.Item>
                        <Form.Item className="form-item" label="Provinsi Domisili" name="provinsiDomisili" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                          <Input size="large" placeholder="Provinsi Domisili" maxLength={80} />
                        </Form.Item>
                        <Form.Item className="form-item" label="Kabupaten/Kota Domisili" name="kabupatenKotaDomisili" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                          <Input size="large" placeholder="Kabupaten/Kota Domisili" maxLength={80} />
                        </Form.Item>
                        <Form.Item className="form-item" label="Kecamatan Domisili" name="kecamatanDomisili" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                          <Input size="large" placeholder="Kecamatan" maxLength={80} />
                        </Form.Item>
                        <Form.Item className="form-item" label="Kelurahan Domisili" name="kelurahanDomisili" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                          <Input size="large" placeholder="Kelurahan" maxLength={80} />
                        </Form.Item>
                        <Form.Item className="form-item" label="RT Domisili" name="rtDomisili" normalize={allowedInputNumberOnly} rules={[{ required: true }]}>
                          <Input size="large" placeholder="RT Domisili" maxLength={3} showCount />
                        </Form.Item>
                        <Form.Item className="form-item" label="RW Domisili" name="rwDomisili" normalize={allowedInputNumberOnly} rules={[{ required: true }]}>
                          <Input size="large" placeholder="RW Domisili" maxLength={3} showCount />
                        </Form.Item>
                        <Form.Item className="form-item" label="Kode Pos Domisili" name="kodePosDomisili" rules={[{ required: true }, { pattern: /^\d+$/, format: 'number' }]}>
                          <Input size="large" placeholder="Kode Pos Domisili" maxLength={5} showCount />
                        </Form.Item>
                      </Fragment>
                    )}
                  </Form.Item>
                  <Form.Item className="form-item" label="Tempat Lahir" name="tempatLahir" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Tempat Lahir" maxLength={30} />
                  </Form.Item>
                  <Form.Item className="form-item" label="Jenis Kelamin" name="jenisKelamin" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      placeholder="Jenis Kelamin"
                      popupClassName="form-item-select"
                      options={[
                        { value: "Pria", label: 'Pria' },
                        { value: "Wanita", label: 'Wanita' }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item className="form-item" label="Status Perkawinan" name="statusPerkawinan" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      placeholder="Status Perkawinan"
                      popupClassName="form-item-select"
                      options={[
                        { value: "Belum Kawin", label: 'Belum Kawin' },
                        { value: "Duda", label: 'Duda' },
                        { value: "Janda", label: 'Janda' },
                        { value: "Kawin", label: 'Kawin' }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item className="form-item" label="Agama" name="agama" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      placeholder="Agama"
                      popupClassName="form-item-select"
                      options={[
                        { value: "BUDHA", label: 'BUDHA' },
                        { value: "HINDU", label: 'HINDU' },
                        { value: "ISLAM", label: 'ISLAM' },
                        { value: "KATOLIK", label: 'KATOLIK' },
                        { value: "PROTESTAN", label: 'PROTESTAN' },
                        { value: "LAINNYA", label: 'LAINNYA' }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item className="form-item" label="Pendidikan" name="pendidikan" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      placeholder="Pendidikan"
                      popupClassName="form-item-select"
                      options={[
                        { value: "S1", label: 'DIPLOMA IV/ STRATA I' },
                        { value: "S2", label: 'Strata 2' },
                        { value: "S3", label: 'Strata 3' },
                        { value: "SD", label: 'SD/SEDERAJAT' },
                        { value: "SMP", label: 'SLTP/SEDERAJAT / SMP' },
                        { value: "SMU", label: 'SLTA/SEDERAJAT / SMU / SMK' },
                        { value: "LAINNYA", label: 'LAINNYA' }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item className="form-item" label="Tipe Pekerjaan" name="tipePekerjaan" rules={[{ required: true }]}>
                    <Select size="large" placeholder="Tipe Pekerjaan" popupClassName="form-item-select" options={JobTypeJson} />
                  </Form.Item>
                  <Form.Item className="form-item" label="Nama Kantor/Tempat Usaha" name="namaKantor" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Nama Kantor/Tempat Usaha" maxLength={40} />
                  </Form.Item>
                  <Form.Item className="form-item" label="Alamat Kantor/Tempat Usaha" name="alamatKantor" rules={[{ required: true }]}>
                    <TextArea size="large" placeholder="Alamat Kantor/Tempat Usaha" maxLength={80} autoSize />
                  </Form.Item>
                  <Form.Item className="form-item" label="Provinsi" name="provinsiKantor" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Provinsi" maxLength={80} />
                  </Form.Item>
                  <Form.Item className="form-item" label="Kabupaten/Kota" name="kabupatenKantor" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Kabupaten/Kota" maxLength={80} />
                  </Form.Item>
                  <Form.Item className="form-item" label="Kecamatan" name="kecamatanKantor" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Kecamatan" maxLength={80} />
                  </Form.Item>
                  <Form.Item className="form-item" label="Kelurahan" name="kelurahanKantor" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Kelurahan" maxLength={80} />
                  </Form.Item>
                  <Form.Item className="form-item" label="RT" name="rtKantor" normalize={allowedInputNumberOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="RT" maxLength={3} showCount />
                  </Form.Item>
                  <Form.Item className="form-item" label="RW" name="rwKantor" normalize={allowedInputNumberOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="RW" maxLength={3} showCount />
                  </Form.Item>
                  <Form.Item className="form-item" label="Kode Pos" name="kodePosKantor" normalize={allowedInputNumberOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Kode Pos" maxLength={5} showCount />
                  </Form.Item>
                  <Form.Item
                    className="form-item"
                    label="Nomor Telepon Kantor/Tempat Usaha"
                    name="teleponKantor"
                    normalize={allowedInputNumberOnly}
                    rules={[{ required: true, min: 9 }]}
                  >
                    <Input size="large" placeholder="Nomor Telepon Kantor/Tempat Usaha" maxLength={13} showCount />
                  </Form.Item>
                  <Form.Item className="form-item" label="Sumber Dana" name="sumberDana" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      placeholder="Sumber Dana"
                      popupClassName="form-item-select"
                      options={[
                        { value: "Gaji", label: 'Gaji' },
                        { value: "Hasil Usaha", label: 'Hasil Usaha' },
                        { value: "Lainnya", label: 'Lainnya' }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item className="form-item" label="Penghasilan Perbulan" name="penghasilanPerbulan" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      placeholder="Penghasilan Perbulan"
                      popupClassName="form-item-select"
                      options={[
                        { value: "s.d. 5 Juta", label: 's.d. 5 Juta' },
                        { value: "5 - 10 juta", label: '5 - 10 juta' },
                        { value: "10 - 50 juta", label: '10 - 50 juta' },
                        { value: "50 - 100 juta", label: '50 - 100 juta' },
                        { value: "Di atas 100 juta", label: 'Di atas 100 juta' }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item className="form-item" label="Transaksi Harian" name="transaksiHarian" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      placeholder="Transaksi Harian"
                      popupClassName="form-item-select"
                      options={[
                        { value: "s.d. 10 juta", label: 's.d. 10 juta' },
                        { value: "s.d. 50 juta", label: 's.d. 50 juta' },
                        { value: "s.d. 100 juta", label: 's.d. 100 juta' },
                        { value: "s.d. 1 milyar", label: 's.d. 1 milyar' },
                        { value: "Lebih dari 1 milyar", label: 'Lebih dari 1 milyar' }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item className="form-item" label="Tujuan Pembuatan Rekening" name="tujuanPembuatanRekening" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      placeholder="Tujuan Pembuatan Rekening"
                      popupClassName="form-item-select"
                      options={[
                        { value: "Tabungan", label: 'Tabungan' },
                        { value: "Transaksi", label: 'Transaksi' },
                        { value: "Pribadi", label: 'Pribadi' },
                        { value: "Lainnya", label: 'Lainnya' }
                      ]}
                    />
                  </Form.Item>
                </Fragment>
              )}
            </Form.Item>
          </div>
          <div className="flex">
            <div className="w-1/2" />
            <Button type="primary" htmlType="submit" size="large" className="w-1/2 max-w-[317px]" loading={loading}>
              Submit eForm
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default OpenSavingAccount;