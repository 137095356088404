function BackgroundBase() {
  return (
    <div className="base_background">
      <svg width={1243} height="100%" viewBox="0 0 1024 672" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.2">
          <path d="M-171.961 676.179C-3.42721 578.239 134.335 556.261 232.935 556.162C315.136 556.085 336.774 571.257 406.971 559.95C559.298 535.411 743.686 417.854 728.48 292.196C720.988 230.297 663.04 213.312 588.222 136.917C520.919 68.193 443.766 -45.8749 416.063 -241.057" stroke="white" strokeMiterlimit={10} />
          <path d="M-152.261 688.523C4.73402 588.785 137.104 564.443 231.447 561.616C310.842 559.247 333.571 569.746 404.871 555.623C554.441 525.996 728.687 416.392 718.626 293.976C713.448 230.923 660.248 209.539 588.19 130.269C524.752 60.4807 450.227 -53.155 422.058 -245.559" stroke="white" strokeMiterlimit={10} />
          <path d="M-132.596 700.853C12.8797 599.326 139.93 572.578 229.966 567.059C306.668 562.357 330.326 568.303 402.78 551.29C549.549 516.827 714.03 414.785 708.798 295.744C705.969 231.434 657.46 205.851 588.168 123.614C528.549 52.8471 456.695 -60.4403 428.07 -250.072" stroke="white" strokeMiterlimit={10} />
          <path d="M-112.962 713.172C21.0136 609.861 142.826 580.647 228.502 572.51C302.588 565.454 327.048 566.945 400.699 546.947C544.645 507.895 699.722 413.057 698.986 297.505C698.572 231.853 654.664 202.25 588.15 116.95C532.287 45.3133 463.16 -67.7338 434.059 -254.582" stroke="white" strokeMiterlimit={10} />
          <path d="M-93.3622 725.476C29.1366 620.369 145.784 588.626 227.013 577.937C298.609 568.512 323.769 565.646 398.607 542.632C539.722 499.217 685.738 411.259 689.172 299.285C691.231 232.199 651.822 198.756 588.117 110.308C535.951 37.8785 469.598 -75.0112 440.041 -259.072" stroke="white" strokeMiterlimit={10} />
          <path d="M-73.7982 737.767C37.231 630.896 148.809 596.511 225.538 583.372C294.743 571.521 320.461 564.462 396.526 538.303C534.833 490.745 672.094 409.376 679.384 301.055C683.997 232.467 648.935 195.354 588.094 103.655C539.55 30.5141 476.037 -82.2974 445.999 -263.596" stroke="white" strokeMiterlimit={10} />
          <path d="M-54.2672 750.045C45.3064 641.395 151.889 604.264 224.065 588.804C290.988 574.471 317.171 563.351 394.452 533.98C529.982 482.469 658.771 407.446 669.609 302.823C676.873 232.683 646.013 192.004 588.074 97.0046C543.074 23.22 482.475 -89.5939 451.963 -268.11" stroke="white" strokeMiterlimit={10} />
          <path d="M-34.76 762.302C53.3989 651.893 155.034 611.864 222.603 594.225C287.346 577.326 313.917 562.307 392.39 529.652C525.189 474.37 645.765 405.474 659.857 304.582C669.88 232.845 643.022 188.738 588.062 90.3445C546.519 15.9969 488.897 -96.8658 457.919 -272.629" stroke="white" strokeMiterlimit={10} />
          <path d="M-15.3042 774.562C61.4518 662.395 158.201 619.311 221.13 599.665C283.797 580.09 310.681 561.394 390.318 525.34C520.452 466.444 633.039 403.506 650.109 306.362C663.01 232.994 639.932 185.556 588.036 83.704C549.883 8.82373 495.301 -104.162 463.876 -277.164" stroke="white" strokeMiterlimit={10} />
          <path d="M4.11623 786.792C69.4911 672.87 161.384 626.554 219.657 605.086C280.315 582.737 307.462 560.522 388.251 521.02C515.774 458.664 620.571 401.541 640.375 308.126C656.274 233.13 636.753 182.417 588.011 77.0503C553.155 1.69354 501.7 -111.459 469.796 -281.679" stroke="white" strokeMiterlimit={10} />
          <path d="M23.5235 799.019C77.5423 683.337 164.584 633.601 218.207 610.511C276.919 585.234 304.328 559.744 386.207 516.708C511.2 451.012 608.38 399.603 630.677 309.903C649.722 233.277 633.489 179.34 588.007 70.4072C556.36 -5.3864 508.107 -118.746 475.744 -286.198" stroke="white" strokeMiterlimit={10} />
          <path d="M42.8834 811.225C85.5467 693.814 167.74 640.429 216.748 615.934C273.532 587.556 301.252 559.032 384.16 512.4C506.701 443.468 596.409 397.695 620.983 311.675C643.334 233.444 630.111 176.299 587.998 63.7638C559.477 -12.4446 514.493 -126.039 481.663 -290.726" stroke="white" strokeMiterlimit={10} />
          <path d="M62.2119 823.417C93.5497 704.27 170.826 647.044 215.294 621.355C270.119 589.683 298.222 558.409 382.12 508.09C502.291 436.017 584.648 395.84 611.309 313.445C637.125 233.661 626.622 173.289 587.989 57.1188C562.514 -19.4832 520.874 -133.332 487.58 -295.258" stroke="white" strokeMiterlimit={10} />
          <path d="M81.5038 835.59C101.541 714.716 173.816 653.455 213.842 626.773C266.617 591.594 295.272 557.845 380.079 503.79C497.963 428.651 573.056 394.043 601.649 315.227C631.125 233.93 623.009 170.307 587.979 50.481C565.475 -26.5135 527.24 -140.631 493.496 -299.783" stroke="white" strokeMiterlimit={10} />
          <path d="M100.76 847.747C109.511 725.152 176.667 659.683 212.384 632.199C262.942 593.289 292.388 557.351 378.047 499.492C493.731 421.351 561.655 392.339 592.003 317.009C625.328 234.292 619.281 167.333 587.964 43.8387C568.346 -33.5347 533.596 -147.922 499.374 -304.321" stroke="white" strokeMiterlimit={10} />
          <path d="M119.985 859.89C117.481 735.569 179.355 665.764 210.945 637.603C259.03 594.739 289.598 556.897 376.029 495.182C489.602 414.106 550.397 390.7 582.389 318.78C619.763 234.749 615.452 164.358 587.981 37.1963C571.181 -40.5733 539.97 -155.223 505.285 -308.86" stroke="white" strokeMiterlimit={10} />
          <path d="M139.182 872.001C125.427 745.988 191.703 677.71 209.514 643.005C238.644 586.238 286.893 556.493 374.024 490.873C485.554 406.886 539.259 389.167 572.783 320.562C614.432 235.353 611.478 161.368 587.984 30.5581C573.942 -47.6111 546.32 -162.52 511.183 -313.394" stroke="white" strokeMiterlimit={10} />
          <path d="M158.846 884.832C133.797 757.033 193.112 682.798 208.614 649.036C235.786 589.863 284.907 556.764 372.798 487.206C482.571 400.344 529.262 388.409 564.28 322.978C610.469 236.719 608.524 158.935 589.04 24.3947C577.654 -54.2502 553.601 -169.48 517.894 -317.705" stroke="white" strokeMiterlimit={10} />
          <path d="M178.688 897.858C142.295 768.242 194.076 688.189 207.851 655.215C233.567 593.665 283.158 557.218 371.778 483.683C479.873 393.969 519.543 387.909 556.036 325.558C607.026 238.432 605.722 156.618 590.373 18.3615C581.587 -60.7901 561.145 -176.341 524.855 -321.934" stroke="white" strokeMiterlimit={10} />
          <path d="M198.55 910.889C150.792 779.451 189.749 691.971 207.058 661.378C241.17 601.132 281.449 557.682 370.7 480.127C477.198 387.567 509.778 387.497 547.704 328.094C603.73 240.32 602.712 154.222 591.631 12.2801C585.407 -67.4047 568.641 -183.233 531.76 -326.193" stroke="white" strokeMiterlimit={10} />
          <path d="M218.435 923.928C159.288 790.662 191.977 698.966 206.237 667.539C235.558 602.925 279.778 558.171 369.582 476.557C474.542 381.155 499.96 387.203 539.311 330.616C600.631 242.443 599.574 151.785 592.861 6.20419C589.159 -74.0449 576.119 -190.117 538.663 -330.441" stroke="white" strokeMiterlimit={10} />
          <path d="M238.343 936.974C167.784 801.87 194.007 706.193 205.389 673.685C229.465 604.893 278.152 558.671 368.428 472.962C471.913 374.711 490.065 387.016 530.86 333.11C597.678 244.797 596.261 149.271 594.046 0.0953851C592.849 -80.7355 583.563 -197.026 545.533 -334.707" stroke="white" strokeMiterlimit={10} />
        </g>
      </svg>
    </div>
  )
}

export default BackgroundBase;