const TOKEN = "qms_token";

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "expires=" + date.toUTCString();
  }
  if (process.env.REACT_APP_ENV === "development") {
    document.cookie = `${name}=${value}; ${expires}; path=/;`;
    return;
  }
  document.cookie = `${name}=${value}; ${expires}; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN};`;
}

const getCookie = (name) => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

const deleteCookie = (name) => {
  if (process.env.REACT_APP_ENV === "development") {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    return;
  }
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN};`;
}

export const getToken = () => {
  return getCookie(TOKEN);
}

export const saveToken = (token) => {
  setCookie(TOKEN, token, 14)
}

export const destroyToken = () => {
  deleteCookie(TOKEN);
}

export const getSessionStorage = (key) => {
  if (!key) return;

  const data = sessionStorage.getItem(key);
  if (!data) return;
  
  return JSON.parse(data);
}

export const saveSessionStorage = (key, data = {}) => {
  if (typeof(Storage) === "undefined" || !key) return;
  sessionStorage.setItem(key, JSON.stringify(data));
}

export const removeSessionStorage = (key) => {
  if (typeof(Storage) === "undefined" || !key) return;
  sessionStorage.removeItem(key);
}