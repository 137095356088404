import { useState } from "react";
import { App, Button, Form, Input, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { submitEformApi } from "../../services/api.service";
import { getSessionStorage, saveSessionStorage } from '../../services/jwt.service';
import { allowedInputNumberOnly, allowedInputTextOnly, formatedCurrency, showCountFormatedText } from "../../utils/helper";
import { ArrowLeftIcon } from "../../components/SvgIcon";
import BRIQueLogo from "../../assets/images/brique-logo-image.png";

function DepositoWithdrawal() {
  const navigate = useNavigate();
  const { index } = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const formIdx = index ? index - 1 : 0;
  const transactionsForm = getSessionStorage("transactions_form") || [];
  const transactionDetail = transactionsForm[formIdx] || {}

  const onUpdateEforms = () => {
    transactionsForm[formIdx].filled = true;
    saveSessionStorage("transactions_form", transactionsForm);
  }

  const onFinish = async (values) => {
    try {
      const request = {
        prefix: transactionDetail.code,
        data: {
          ...values,
          jumlahKredit: values?.jumlahKredit?.replace(/\./g, '') || ""
        }
      }
      setLoading(true);
      const response = await submitEformApi(request);
      if (response?.status != 200) throw response.message;

      setLoading(false);
      onUpdateEforms();
      navigate(-1);
    } catch (error) {
      setLoading(false);
      message.error(error);
      console.log("Error onSubmitEform:", error)
    }
  }

  return (
    <div className="w-screen h-screen">
      <div className="w-full flex items-center gap-1 p-12">
        <div className="flex mr-4 cursor-pointer" onClick={() => navigate(-1)}>
          <ArrowLeftIcon />
        </div>
        <div className="flex max-w-[30px]">
          <img src={BRIQueLogo} alt="" />
        </div>
        <div className="text-white text-2xl font-prompt font-medium">
          BRIQue
        </div>
      </div>
      <div className="relative h-[calc(100%-128px)] overflow-y-auto bg-[#F9FAFB] rounded-t-3xl px-14 py-12">
        <div className="text-[#212B36] text-2xl font-semibold mb-4">{transactionDetail?.label}</div>
        <div className="text-[#637381] text-sm mb-4">Isi formulir dibawah ini dengan teliti</div>
        <Form
          size="small"
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            string: {
              min: 'Input must be minimum ${min} character!',
              range: 'Input minimum & maximum must be ${min} character!'
            }
          }}
          onFinish={onFinish}
        >
          <div className="grid grid-cols-[repeat(2,_1fr)]">
            <Form.Item
              className="form-item"
              label="Nomor Rekening Deposito"
              name="nomorRekening"
              normalize={allowedInputNumberOnly}
              rules={[{ required: true, min: 15, max: 15 }]}
            >
              <Input size="large" placeholder="Nomor Rekening Deposito" maxLength={15} showCount />
            </Form.Item>
            <Form.Item className="form-item" label="Nama Nasabah" name="namaPemilikRekeningAsal" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
              <Input size="large" placeholder="Nama Nasabah" maxLength={80} />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Nomor Rekening Tujuan"
              name="nomorRekeningTujuan"
              normalize={allowedInputNumberOnly}
              rules={[{ required: true, min: 15, max: 15 }]}
            >
              <Input size="large" placeholder="Nomor Rekening Tujuan" maxLength={15} showCount />
            </Form.Item>
            <Form.Item className="form-item" label="Jenis Penarikan Deposito" name="jenisPenarikanDeposito" rules={[{ required: true }]}>
              <Select
                size="large"
                placeholder="Jenis Penarikan Deposito"
                popupClassName="form-item-select"
                options={[
                  { value: 'Penuh', label: 'Penuh' },
                  { value: 'Sebagian', label: 'Sebagian' }
                ]}
              />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.jenisPenarikanDeposito !== next.jenisPenarikanDeposito}>
              {({ getFieldValue }) => getFieldValue('jenisPenarikanDeposito')?.includes("Sebagian") && (
                <Form.Item className="form-item" label="Jumlah Penarikan" name="jumlahKredit" normalize={formatedCurrency} rules={[{ required: true }]}>
                  <Input
                    size="large"
                    placeholder="Jumlah Penarikan"
                    maxLength={16}
                    showCount={{ formatter: (info) => showCountFormatedText(info, 13) }}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item className="form-item" label="No. Bilyet" name="noBilyet" normalize={allowedInputNumberOnly} rules={[{ required: true }]}>
              <Input size="large" placeholder="No. Bilyet" maxLength={12} showCount />
            </Form.Item>
          </div>
          <div className="flex">
            <div className="w-1/2" />
            <Button type="primary" htmlType="submit" size="large" className="w-1/2 max-w-[317px]" loading={loading}>
              Submit eForm
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default DepositoWithdrawal;