import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Camera from "react-webcam";
import { App, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { checkInApi } from "../services/api.service";
import { getSessionStorage, saveSessionStorage } from "../services/jwt.service";
import { ArrowLeftIcon } from "../components/SvgIcon";

const branchCode = process.env.REACT_APP_BRANCH_CODE;
function WebCamera() {
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const { message } = App.useApp();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight - 50);
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState("");
  const [navigatePosition, setNavigatePosition] = useState("");
  const bookingCode = getSessionStorage("booking_code");

  useEffect(() => {
    const updateScreenDimensions = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight - 50);
    };

    window.addEventListener('resize', updateScreenDimensions);
    return () => {
      window.removeEventListener('resize', updateScreenDimensions);
    };
  }, []);

  useEffect(() => {
    if (screenWidth > screenHeight) {
      setNavigatePosition("right");
    } else {
      setNavigatePosition("bottom");
    }
  }, [screenWidth, screenHeight])

  const onCapture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhoto(imageSrc);
  }, [webcamRef]);

  const onTakePhoto = async () => {
    try {
      const request = {
        branchCode: branchCode,
        kode_booking: bookingCode,
        foto: photo
      }

      setLoading(true);
      const response = await checkInApi(request);
      if (response?.status != 200) throw response?.message;

      setLoading(false);
      saveSessionStorage("queue", response?.data);
      navigate('/queue');
    } catch (error) {
      setLoading(false);
      message.error(error);
      console.log("Error on onCheckIn:", error);
    }
  }

  function ButtonCapture({ className }) {
    return (
      <div className={className} onClick={onCapture}>
        <svg width={70} height={70} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx={35} cy={35} r={35} fill="white" />
          <circle cx={35} cy={35} r={28} fill="white" stroke="black" strokeWidth={2} />
        </svg>
      </div>
    )
  }

  if (photo) {
    return (
      <div className="relative bg-black flex justify-center items-center" style={{ height: '100dvh' }}>
        <img className="h-fit" src={photo} />
        {navigatePosition === "bottom" &&
          <div className="absolute bottom-0 w-full flex p-14">
            <div className="text-white text-lg mx-auto" onClick={() => setPhoto("")}>Retake</div>
            <div className="text-white text-lg mx-auto" onClick={onTakePhoto}>Use Photo</div>
          </div>
        }
        {navigatePosition === "right" &&
          <div className="absolute right-0 h-full p-5 flex flex-col">
            <div className="text-white text-lg my-auto" onClick={onTakePhoto}>Use Photo</div>
            <div className="text-white text-lg my-auto" onClick={() => setPhoto("")}>Retake</div>
          </div>
        }
        {loading &&
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-5">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 96 }} spin className="text-white" />} />
            <div className="text-white text-3xl font-semibold">Uploading....</div>
          </div>
        }
      </div>
    )
  }

  return (
    <div className="relative flex justify-center bg-black" style={{ height: '100dvh' }}>
      <Camera
        mirrored
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          width: screenHeight,
          height: screenWidth,
          facingMode: "user"
        }}
      />
      <div className="absolute top-7 left-7">
        <ArrowLeftIcon width={36} height={36} onClick={() => navigate(-1)} />
      </div>
      {navigatePosition === "bottom" &&
        <ButtonCapture className="absolute bottom-7 left-1/2 -translate-x-1/2" />
      }
      {navigatePosition === "right" &&
        <ButtonCapture className="absolute right-7 top-1/2 -translate-y-1/2" />
      }
    </div>
  )
}

export default WebCamera;