import { Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken } from "../services/jwt.service";
import NotFoundPage from "../components/NotFoundPage";
import Layout from "../pages/Layout";
import Landing from "../pages/Landing";
import BookingForm from "../pages/BookingForm";
import WebCamera from "../pages/WebCamera";
import QueueResult from "../pages/QueueResult";

import BookingListForm from "../pages/BookingListForm";
import Deposit from "../pages/financial-eform/Deposit";
import Withdrawal from "../pages/financial-eform/Withdrawal";
import Overbooking from "../pages/financial-eform/Overbooking";
import DepositWIC from "../pages/financial-eform/DepositWIC";
import Clearing from "../pages/financial-eform/Clearing";
import RealtimeGrossSettlement from "../pages/financial-eform/RealtimeGrossSettlement";
import PaymentBriva from "../pages/financial-eform/PaymentBriva";
import CheckEncashment from "../pages/financial-eform/CheckEncashment";
import ValutaAsing from "../pages/financial-eform/ValutaAsing";

import RegisterNotification from "../pages/non-financial-eform/RegisterNotification";
import RegisterInternetBanking from "../pages/non-financial-eform/RegisterInternetBanking";
import DepositoWithdrawal from "../pages/non-financial-eform/DepositoWithdrawal";
import SavingMutation from "../pages/non-financial-eform/SavingMutation";
import BilyetGiroFacilities from "../pages/non-financial-eform/BilyetGiroFacilities";
import OpenSavingAccount from "../pages/non-financial-eform/OpenSavingAccount";

function RoutesManager() {
  const navigate = useNavigate();

  axios.interceptors.request.use((config) => {
    config.baseURL = process.env.REACT_APP_API_URL + "/api/";
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json"
    };
    if (!!getToken()) config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  });

  axios.interceptors.response.use((response) => {
    return (response?.data?.data || response?.data?.message) ? response.data : response;
  }, (error) => {
    const message = error?.response?.data?.message || error?.response?.statusText || error?.message || error;
    if (message?.includes("Unauthenticated")) {
      navigate('/landing');
      return Promise.reject("Your session expired.");
    }
    return Promise.reject(message);
  });

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="/landing" replace />} />
        <Route path="landing" element={<Landing />} />
        <Route path="booking/:type" element={<BookingForm />} />
        <Route path="opencam" element={<WebCamera />} />
        <Route path="queue" element={<QueueResult />} />
        <Route path="eform/:type" element={<Outlet />}>
          <Route index element={<BookingListForm />} />
          <Route path="setoran-tunai/:index" element={<Deposit />} />
          <Route path="tarik-tunai/:index" element={<Withdrawal />} />
          <Route path="overbooking/:index" element={<Overbooking />} />
          <Route path="setor-tunai-wic/:index" element={<DepositWIC />} />
          <Route path="kliring/:index" element={<Clearing />} />
          <Route path="rtgs/:index" element={<RealtimeGrossSettlement />} />
          <Route path="payment-briva/:index" element={<PaymentBriva />} />
          <Route path="pencairan-cek-bg/:index" element={<CheckEncashment />} />
          <Route path="transaksi-valuta-asing/:index" element={<ValutaAsing />} />
          <Route path="registrasi-notifikasi-sms-wa-email/:index" element={<RegisterNotification />} />
          <Route path="registrasi-internet-banking/:index" element={<RegisterInternetBanking />} />
          <Route path="pencairan-deposito/:index" element={<DepositoWithdrawal />} />
          <Route path="penggantian-butab/:index" element={<SavingMutation />} />
          <Route path="permintaan-fasilitas-cek-bg/:index" element={<BilyetGiroFacilities />} />
          <Route path="open-account-savings/:index" element={<OpenSavingAccount />} />
        </Route>
        <Route path="404" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to="404" replace />} />
      </Route>
    </Routes>
  );
};

export default RoutesManager;