import { useEffect, useState } from "react";
import { Alert, App, Button, Form, Input, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { submitEformApi } from './../services/api.service';
import { getSessionStorage, saveSessionStorage } from './../services/jwt.service';
import { ArrowLeftIcon, CircleCheckIcon, CircleUncheckIcon, EditPencilIcon } from "../components/SvgIcon";
import BRIQueLogo from "../assets/images/brique-logo-image.png";
import FinancialCardImg from "../assets/images/financial-card-image.png";
import NonFinancialCardImg from "../assets/images/nonfinancial-card-image.png";

const bookingType = {
  "financial": FinancialCardImg,
  "non-financial": NonFinancialCardImg
}

function BookingListForm() {
  const navigate = useNavigate();
  const { type } = useParams();
  const { message } = App.useApp();
  const [transactions, setTransactions] = useState([]);
  const [openEformModal, setOpenEformModal] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const transactionsForm = getSessionStorage("transactions_form") || [];

  const bannerImage = bookingType?.[type] || "";

  useEffect(() => {
    const sources = [...transactionsForm];
    if (sources?.length) setTransactions(sources);
  }, [])

  const onAddedTrxItem = (data, index) => {
    if (!data && typeof index !== "number") return;
    let trxArrData = [...transactions];
    trxArrData.splice(index, 0, { ...data, isParent: false, filled: false });
    setTransactions(trxArrData);
    saveSessionStorage("transactions_form", trxArrData);
  }

  const onRemoveTrxItem = (index) => {
    if (typeof index !== "number") return;
    let trxArrData = [...transactions];
    trxArrData.splice(index, 1);
    setTransactions(trxArrData);
    saveSessionStorage("transactions_form", trxArrData);
  }

  const onClickNext = () => {
    const isNotFilledForm = transactions.some(data => !data.filled);
    if (isNotFilledForm && transactions?.length < 5) {
      message.error("Please fill all form!");
      return;
    }
    if (transactions?.length >= 5) {
      setOpenEformModal(true);
      return;
    }
    navigate('/opencam');
  }

  const onSubmitSpecialForm = async (values) => {
    try {
      const request = {
        prefix: "UNK99",
        data: values
      }
      setLoadingForm(true);
      const response = await submitEformApi(request);
      if (response?.status != 200) throw response?.message;

      setLoadingForm(false);
      setOpenEformModal(false);
      message.success("Submit form successfully!");
      navigate('/opencam');
    } catch (error) {
      setLoadingForm(false);
      message.error(error);
      console.log("Error onSubmitSpecialForm:", error)
    }
  }

  function renderEformList() {
    return transactions?.map((data, index) => {
      return (
        <div
          key={index}
          className="relative flex items-center gap-4 bg-white rounded-lg border-[1px] border-[#C4CDD5] p-[14px_22px] cursor-pointer"
          onClick={() => {
            if (data?.filled || transactions?.length >= 5) return;
            navigate(`${data?.path}/${index + 1}`);
          }}
        >
          <div className="absolute -left-2">
            {data?.isParent &&
              <PlusCircleFilled
                style={{ color: "#00529C", fontSize: 20 }}
                onClick={(e) => { e.stopPropagation(); onAddedTrxItem(data, index + 1); }}
              />
            }
            {!data?.isParent &&
              <MinusCircleFilled
                style={{ color: "#212B36", fontSize: 20 }}
                onClick={(e) => { e.stopPropagation(); onRemoveTrxItem(index); }}
              />
            }
          </div>
          {data?.filled ? <CircleCheckIcon /> : <CircleUncheckIcon />}
          <div className="flex flex-col gap-1 mr-auto">
            <div className="flex items-center gap-[6px]">
              <div className="text-sm font-semibold text-[#212B36]">{data?.label}</div>
              <div className="text-[10px] font-semibold text-[#33A0FF] whitespace-nowrap">5 menit</div>
            </div>
            {data?.filled &&
              <div className="w-fit bg-[#E3F1DF] text-[10px] font-bold text-[#108043] rounded-full px-2">Formulir Telah Terisi</div>
            }
            {!data?.filled &&
              <div className="w-fit bg-[#F4F6F8] text-[10px]  font-bold text-[#919EAB] rounded-full px-2">Formulir Belum Terisi</div>
            }
          </div>
          {(!data?.filled && transactions?.length < 5) && <EditPencilIcon />}
        </div>
      )
    })
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="w-full flex items-center gap-1 p-12">
        <div className="flex mr-4 cursor-pointer" onClick={() => navigate(`/booking/${type}`)}>
          <ArrowLeftIcon />
        </div>
        <div className="flex max-w-[30px]">
          <img src={BRIQueLogo} alt="" />
        </div>
        <div className="text-white text-2xl font-prompt font-medium">
          BRIQue
        </div>
      </div>
      <div className="relative bg-[#F9FAFB] rounded-t-3xl px-14 py-12 mt-auto">
        {transactions?.length >= 5 &&
          <Alert message="5 or more eForm. You can go next instead." type="warning" className="!mb-4" showIcon />
        }
        <div className="flex">
          <div className="w-1/2 flex flex-col z-[1]">
            <div className="text-[#212B36] text-2xl font-semibold px-10 mb-4">Pengisian Formulir</div>
            <div className="text-[#637381] text-sm px-10 mb-11">
              Isi formulir disamping ini untuk mempercepat layanan
            </div>
            <div className="flex max-w-[257px] h-[309px] mt-auto">
              <img src={bannerImage} alt="" />
            </div>
          </div>
          <div className="w-1/2 max-w-[369px] flex flex-col">
            <div className="max-h-[333px] overflow-y-auto flex flex-col gap-5 p-2 mb-auto">
              {renderEformList()}
            </div>
            <div className="flex items-center justify-between mb-3">
              <div className="text-[#454F5B] text-sm font-semibold">Total Transaksi</div>
              <div className="text-sm font-semibold text-[#33A0FF]">{transactions?.length} Item</div>
            </div>
            <div className="flex items-center justify-between mb-3">
              <div className="text-[#454F5B] text-sm font-semibold">Total Waktu Layanan</div>
              <div className="text-sm font-semibold text-[#33A0FF]">{transactions?.length * 5} Menit</div>
            </div>
            <Button type="primary" size="large" className="full-btn" onClick={onClickNext}>Next</Button>
          </div>
        </div>
        <div className="fixed left-0 bottom-0 -translate-x-20 translate-y-20 w-[353px] h-[353px] rounded-full bg-[#FFB121] z-0 overflow-hidden" />
      </div>
      <Modal
        open={openEformModal}
        closeIcon={null}
        title={null}
        footer={null}
        centered
        onCancel={() => setOpenEformModal(false)}
      >
        <Form
          size="small"
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            pattern: { mismatch: 'Input must be a alphabet!' }
          }}
          onFinish={onSubmitSpecialForm}
        >
          <Form.Item label="Nama Nasabah" name="nama" rules={[{ required: true, pattern: /^[a-zA-Z\s]*$/ }]}>
            <Input size="large" placeholder="Nama Nasabah" maxLength={80} />
          </Form.Item>
          <Button htmlType="submit" type="primary" size="large" className="w-full" loading={loadingForm}>Submit eForm</Button>
        </Form>
      </Modal>
    </div>
  )
}

export default BookingListForm;