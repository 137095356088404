function NotFoundPage() {
  return (
    <div className="w-screen h-screen bg-white flex justify-center items-center">
      <div className="flex items-center h-8 gap-2 font-medium">
        <span className="text-black">404</span>
        <div className="h-6 border-s border-solid border-black"></div>
        <span className="text-black">This page could not be found.</span>
      </div>
    </div>
  );
}

export default NotFoundPage;