import { useState } from "react";
import { Alert, App, Button, Form, Input } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import writtenNumber from "written-number";
import { submitEformApi } from "../../services/api.service";
import { getSessionStorage, saveSessionStorage } from '../../services/jwt.service';
import { allowedInputNumberOnly, allowedInputTextOnly, formatedCurrency, showCountFormatedText } from "../../utils/helper";
import { ArrowLeftIcon } from "../../components/SvgIcon";
import BRIQueLogo from "../../assets/images/brique-logo-image.png";

const { TextArea } = Input;

function Overbooking() {
  const navigate = useNavigate();
  const { index } = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const formIdx = index ? index - 1 : 0;
  const transactionsForm = getSessionStorage("transactions_form") || [];
  const transactionDetail = transactionsForm[formIdx] || {}

  const onUpdateEforms = () => {
    transactionsForm[formIdx].filled = true;
    saveSessionStorage("transactions_form", transactionsForm);
  }

  const onPriceChange = (e) => {
    const value = e.target.value?.replace(/\./g, '');
    const regexPattern = /^(\s*|\d+)$/;
    if (!regexPattern.test(value)) return;

    if (!value) {
      form.setFieldValue('terbilang', '');
      return;
    };
    const numbToWords = writtenNumber(value, { lang: 'id' });
    form.setFieldValue('terbilang', `${numbToWords} rupiah`);
    form.validateFields(["terbilang"]);
  }

  const onFinish = async (values) => {
    try {
      const request = {
        prefix: transactionDetail.code,
        data: {
          ...values,
          jumlah: values.jumlah?.replace(/\./g, '') || ""
        }
      }
      setLoading(true);
      const response = await submitEformApi(request);
      if (response?.status != 200) throw response?.message;

      setLoading(false);
      onUpdateEforms();
      navigate(-1);
    } catch (error) {
      setLoading(false);
      message.error(error);
      console.log("Error onSubmitEform:", error)
    }
  }

  return (
    <div className="w-screen h-screen">
      <div className="w-full flex items-center gap-1 p-12">
        <div className="flex mr-4 cursor-pointer" onClick={() => navigate(-1)}>
          <ArrowLeftIcon />
        </div>
        <div className="flex max-w-[30px]">
          <img src={BRIQueLogo} alt="" />
        </div>
        <div className="text-white text-2xl font-prompt font-medium">
          BRIQue
        </div>
      </div>
      <div className="relative h-[calc(100%-128px)] overflow-y-auto bg-[#F9FAFB] rounded-t-3xl px-14 py-12">
        <Alert message="Form hanya bisa di isi sekali." type="info" showIcon />
        <div className="text-[#212B36] text-2xl font-semibold my-4">{transactionDetail?.label}</div>
        <div className="text-[#637381] text-sm mb-4">Isi formulir dibawah ini dengan teliti.</div>
        <Form
          size="small"
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            string: {
              range: 'Input minimum & maximum must be ${min} character!'
            }
          }}
          onFinish={onFinish}
        >
          <div className="grid grid-cols-[repeat(2,_1fr)]">
            <Form.Item
              className="form-item"
              label="Nomor Rekening Asal"
              name="nomorRekeningAsal"
              normalize={allowedInputNumberOnly}
              rules={[{ required: true, min: 15, max: 15 }]}
            >
              <Input size="large" placeholder="Nomor Rekening Asal" maxLength={15} showCount />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Nama Pemilik Rekening Asal"
              name="namaPemilikRekeningAsal"
              normalize={allowedInputTextOnly}
              rules={[{ required: true }]}
            >
              <Input size="large" placeholder="Nama Pemilik Rekening Asal" maxLength={80} />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Nomor Rekening Tujuan"
              name="nomorRekeningTujuan"
              normalize={allowedInputNumberOnly}
              rules={[{ required: true, min: 15, max: 15 }]}
            >
              <Input size="large" placeholder="Nomor Rekening Tujuan" maxLength={15} showCount />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Nama Pemilik Rekening Tujuan"
              name="namaPemilikRekeningTujuan"
              normalize={allowedInputTextOnly}
              rules={[{ required: true }]}
            >
              <Input size="large" placeholder="Nama Pemilik Rekening Tujuan" maxLength={80} />
            </Form.Item>
            <Form.Item className="form-item" label="Jumlah Dana" name="jumlah" normalize={formatedCurrency} rules={[{ required: true }]}>
              <Input
                size="large"
                placeholder="Jumlah Dana"
                maxLength={16}
                showCount={{ formatter: (info) => showCountFormatedText(info, 13) }}
                onChange={onPriceChange}
              />
            </Form.Item>
            <Form.Item className="form-item" label="Terbilang" name="terbilang" rules={[{ required: true }]}>
              <TextArea size="large" placeholder="Terbilang" readOnly autoSize />
            </Form.Item>
          </div>
          <div className="flex">
            <div className="w-1/2" />
            <Button type="primary" htmlType="submit" size="large" className="w-1/2 max-w-[317px]" loading={loading}>
              Submit eForm
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Overbooking;