import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { App, Button, Checkbox, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { submitBookingApi } from "../services/api.service";
import { getSessionStorage, saveSessionStorage } from './../services/jwt.service';
import { ArrowLeftIcon } from "../components/SvgIcon";
import FinancialJson from "../assets/json/financial-form.json";
import NonFinancialJson from "../assets/json/non-financial-form.json";
import BRIQueLogo from "../assets/images/brique-logo-image.png";
import FinancialCardImg from "../assets/images/financial-card-image.png";
import NonFinancialCardImg from "../assets/images/nonfinancial-card-image.png";

const branchCode = process.env.REACT_APP_BRANCH_CODE;
const bookingType = {
  "financial": {
    typeCode: 1,
    text: "Financial Services",
    detail: "Setoran Tunai, Tarik Tunai, Overbooking dan lainnya.",
    image: FinancialCardImg,
    json: FinancialJson
  },
  "non-financial": {
    typeCode: 2,
    text: "Non Financial Services",
    detail: "Registrasi, Pencairan Deposito, Permintaan Fasilitas Cek/BG dan lainnya.",
    image: NonFinancialCardImg,
    json: NonFinancialJson
  }
}

function BookingForm() {
  const navigate = useNavigate();
  const { type } = useParams();
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const transactionsForm = getSessionStorage("transactions_form") || [];

  const typeCode = bookingType?.[type]?.typeCode;
  const headerText = bookingType?.[type]?.text || "";
  const detailText = bookingType?.[type]?.detail || "";
  const bannerImage = bookingType?.[type]?.image || "";
  const trxTypeListSource = Object.values(bookingType?.[type]?.json || []);

  useEffect(() => {
    const trxArr = transactionsForm.reduce((result, data) => {
      if (data.isParent) result.push(data.value);
      return result;
    }, []);
    if (trxArr?.length) setTransactions(trxArr);

    const openSelect = setTimeout(() => setOpen(true), 100);
    return () => clearTimeout(openSelect);
  }, [])

  const onSelectTrx = (data, index) => {
    const valueIdx = transactions.indexOf(data.value);
    let trxArr = [...transactions];
    let trxArrData = [...transactionsForm];
    if (transactions.includes(data?.value)) {
      trxArr.splice(valueIdx, 1);
      trxArrData.splice(valueIdx, 1);
    } else {
      trxArr.splice(index, 0, data?.value);
      trxArrData.splice(index, 0, { ...data, isParent: true, filled: false });
    }
    setTransactions(trxArr);
    saveSessionStorage("transactions_form", trxArrData);
  }

  const onClickNext = async () => {
    try {
      if (!transactions?.length) {
        message.error("Please select the transaction.");
        return;
      }

      const request = {
        branchCode: branchCode,
        tipe_transaksi: transactions?.join(" | "),
        jenis_transaksi: typeCode
      }

      setLoading(true);
      const response = await submitBookingApi(request);
      if (response?.status != 200) throw response?.message;

      setLoading(false);
      saveSessionStorage("booking_code", response?.data?.kode_booking);
      message.success(response.message);
      navigate(`/eform/${type}`);
    } catch (error) {
      setLoading(false);
      message.error(error);
      console.log("Error onClickNext:", error);
    }
  }

  function renderTrxTypeDropdown() {
    return trxTypeListSource.map((data, index) => {
      const className = index === 0 ? "" : "mt-5";
      return (
        <div key={index} className={`flex items-center justify-between cursor-pointer ${className}`} onClick={() => onSelectTrx(data, index)}>
          <div className="text-[#454F5B] text-xs font-semibold">{data.label}</div>
          <Checkbox checked={!!transactions?.includes(data.value)} />
        </div>
      )
    })
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="w-full flex items-center gap-1 p-12">
        <div className="flex mr-4 cursor-pointer" onClick={() => navigate('/landing')}>
          <ArrowLeftIcon />
        </div>
        <div className="flex max-w-[30px]">
          <img src={BRIQueLogo} alt="" />
        </div>
        <div className="text-white text-2xl font-prompt font-medium">
          BRIQue
        </div>
      </div>
      <div className="relative h-full bg-[#F9FAFB] rounded-t-3xl px-14 pt-12">
        <div className="text-[#212B36] text-2xl font-semibold px-10 mb-4">{headerText}</div>
        <div className="text-[#637381] text-sm px-10 mb-4">{detailText}</div>
        <div className="flex">
          <div className="w-1/2 flex z-[1]">
            <div className="flex max-w-[257px] h-[309px] mt-auto">
              <img src={bannerImage} alt="" />
            </div>
          </div>
          <div className="w-1/2 max-w-[369px]">
            <div className="text-[#212B36] text-sm font-semibold mb-4">Transaction</div>
            <Select
              open={open}
              size="large"
              className="select-item w-full"
              popupClassName="select-item-dropdown"
              mode="multiple"
              placeholder="Select Transaction"
              maxTagCount="responsive"
              showSearch={false}
              value={transactions}
              options={trxTypeListSource}
              dropdownRender={renderTrxTypeDropdown}
              popupMatchSelectWidth={false}
              onDeselect={(_, data) => onSelectTrx(data)}
            />
            <Button type="primary" size="large" className="w-full mt-72" loading={loading} onClick={onClickNext}>Next</Button>
          </div>
        </div>
        <div className="fixed left-0 bottom-0 -translate-x-20 translate-y-20 w-[353px] h-[353px] rounded-full bg-[#FFB121] z-0 overflow-hidden" />
      </div>
    </div>
  )
}

export default BookingForm;