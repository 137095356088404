import { removeSessionStorage } from "../services/jwt.service"

export const allowedInputTextOnly = (value, prevValue = "") => {
  const regexPattern = /^[a-zA-Z\s]*$/;
  if (!regexPattern.test(value)) return prevValue;
  return value;
};

export const allowedInputNumberOnly = (value, prevValue = "") => {
  const regexPattern = /^(\s*|\d+)$/;
  if (!regexPattern.test(value)) return prevValue;
  return value;
};

export const formatedCurrency = (value, prevValue = "") => {
  const regexPattern = /^(\s*|\d+)$/;
  const newVal = value.replace(/\./g, '');
  if (!regexPattern.test(newVal) || newVal === '0') return prevValue;
  return newVal.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatedCardNumber = (value, prevValue = "") => {
  const regexPattern = /^(\s*|\d+)$/;
  const newVal = value.replace(/\-/g, '');
  if (!regexPattern.test(newVal)) return prevValue;
  return newVal.replace(/\B(?=(\d{4})+(?!\d))/g, '-');
};

export const showCountFormatedText = ({ value, count }, maxLength) => {
  let exactCount = count;
  if (value?.includes('.')) {
    exactCount = value?.replace(/\./g, '')?.length || count;
  }
  if (value?.includes('-')) {
    exactCount = value?.replace(/\-/g, '')?.length || count;
  }
  return `${exactCount}/${maxLength}`;
};

export const clearActiveSessionStorage = () => {
  removeSessionStorage("booking_code");
  removeSessionStorage("transactions_form");
  removeSessionStorage("queue");
}