export const QrcodeFrame = ({ width = 278, height = 299, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 278 299" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <rect x="40.1414" y="40.1416" width="198.299" height="219.172" fill="url(#pattern0)" />
      <path d="M232.017 15.3275H262.672V45.9825" stroke="#FFFBFB" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M232.017 283.546H262.672V252.891" stroke="#FFFBFB" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M45.9825 15.3275H15.3275V45.9825" stroke="#FFFBFB" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M45.9825 283.546H15.3275V252.891" stroke="#FFFBFB" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
      <line x1="32.9085" y1="274.976" x2="245.658" y2="274.173" stroke="#33A0FF" strokeWidth={4} />
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width={1} height={1}>
          <use xlinkHref="#image0_719_9288" transform="matrix(0.000690789 0 0 0.000625 -0.0526316 0)" />
        </pattern>
      </defs>
    </svg>
  )
}

export const ArrowLeftIcon = ({ width = 24, height = 24, color = "#FFFFFF", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9.57 5.92993L3.5 11.9999L9.57 18.0699" stroke={color} strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.4999 12H3.66992" stroke={color} strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const CircleCheckIcon = ({ width = 30, height = 30, color = "#82C364", borderColor = "#C4E2B6", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={15} cy={15} r={15} fill="url(#paint0_radial_397_6186)" />
      <path d="M12.6001 22C12.495 22.0001 12.391 21.9775 12.2939 21.9335C12.1969 21.8895 12.1087 21.825 12.0345 21.7436L7.23429 16.4937C7.16001 16.4125 7.10109 16.316 7.06089 16.2099C7.02069 16.1038 7 15.99 7 15.8751C7 15.7602 7.02069 15.6465 7.06089 15.5403C7.10109 15.4342 7.16001 15.3377 7.23429 15.2565C7.30857 15.1753 7.39675 15.1108 7.4938 15.0669C7.59085 15.0229 7.69487 15.0003 7.79991 15.0003C7.90496 15.0003 8.00898 15.0229 8.10603 15.0669C8.20308 15.1108 8.29126 15.1753 8.36554 15.2565L12.5545 19.8379L21.5933 8.30563C21.7312 8.12949 21.9275 8.02049 22.1389 8.00261C22.3503 7.98472 22.5595 8.05941 22.7206 8.21025C22.8816 8.36109 22.9813 8.57572 22.9976 8.80693C23.014 9.03813 22.9457 9.26697 22.8078 9.4431L13.2073 21.6929C13.1357 21.7847 13.0475 21.8593 12.9482 21.912C12.8489 21.9647 12.7408 21.9943 12.6305 21.9991L12.6001 22Z" fill="white" />
      <defs>
        <radialGradient id="paint0_radial_397_6186" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(15 15) rotate(90) scale(15)">
          <stop offset="0.270833" stopColor={borderColor} />
          <stop offset={1} stopColor={color} />
        </radialGradient>
      </defs>
    </svg>
  )
}

export const CircleUncheckIcon = ({ width = 30, height = 30, color = "#C4CDD5", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={15} cy="15.3999" r="14.5" stroke={color} />
    </svg>
  )
}

export const EditPencilIcon = ({ width = 24, height = 24, color = "#00529C", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z"
        fill={color}
      />
    </svg>
  )
}