import React, { useEffect, useState } from "react";
import { App, Button, Input, QRCode } from 'antd';
import { useNavigate } from "react-router-dom";
import { checkBookingApi, tokenApi } from './../services/api.service';
import { saveSessionStorage, saveToken } from "../services/jwt.service";
import { clearActiveSessionStorage } from "../utils/helper";
import { QrcodeFrame } from "../components/SvgIcon";
import BRIQueLogo from "../assets/images/brique-logo-image.png";
import FinancialCardImg from "../assets/images/financial-card-image.png";
import NonFinancialCardImg from "../assets/images/nonfinancial-card-image.png";

function Landing() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [bookingCode, setBookingCode] = useState("");
  const branchCode = process.env.REACT_APP_BRANCH_CODE;

  useEffect(() => {
    clearActiveSessionStorage();
    const onGetToken = async () => {
      try {
        const response = await tokenApi(branchCode);
        saveToken(response?.data?.token);
      } catch (error) {
        console.log("Error onGetToken:", error);
      }
    }
    onGetToken();
  }, [])

  const onInputBookingCode = async () => {
    if (!bookingCode) {
      message.error("Please fill kode booking!");
      return;
    }
    try {
      const response = await checkBookingApi(bookingCode);
      if (response?.status != 200) throw response?.message;
      navigate('/opencam');
      saveSessionStorage("booking_code", bookingCode);
    } catch (error) {
      message.error(error);
      console.log("Error onInputBookingCode:", error);
    }
  }

  const onClickCard = (type) => {
    navigate(`/booking/${type}`);
  }

  function CardServiceOption({ bgClassName, textClassName, title, detail, image, ...props }) {
    return (
      <div className={`relative rounded-xl flex px-9 py-6 max-w-[378px] mx-7 lg:mx-auto mb-14 cursor-pointer ${bgClassName}`} onClick={props.onClick}>
        <div className="flex-1">
          <div className="text-white text-xl font-semibold">{title}</div>
          <div className="text-white text-sm mb-3">{detail}</div>
          <div className={`w-fit bg-white rounded-lg px-8 py-3 text-xs font-semibold ${textClassName}`}>
            Choose
          </div>
        </div>
        <div className="absolute -top-5 -right-5 flex max-w-[126px]">
          <img src={image} alt="card-banner" />
        </div>
      </div>
    )
  }

  return (
    <div className="w-screen h-screen flex items-center">
      <div id="left-container" className="w-1/2">
        <div className="w-full flex justify-center gap-1 mb-10">
          <div className="flex max-w-[30px]">
            <img src={BRIQueLogo} alt="" />
          </div>
          <div className="text-white text-2xl font-prompt font-medium">
            BRIQue
          </div>
        </div>
        <div className="text-white text-2xl text-center font-extrabold mb-4">Scan QR</div>
        <div className="max-w-[269px] text-white text-center mx-auto mb-4">
          Scan QR untuk check-in atau ambil antrian dari smartphone Anda
        </div>
        <div className="relative flex justify-center items-center mb-5">
          <QrcodeFrame />
          <div className="absolute bg-white px-1 py-2">
            <QRCode size={198} value={`${process.env.REACT_APP_BRIQUE_URL}/history/${branchCode}`} />
          </div>
        </div>
        <div className="max-w-[394px] flex items-center bg-white rounded-lg p-4 gap-7 mx-8 lg:mx-auto">
          <div className="flex flex-col gap-4">
            <div className="text-[#00529C] text-xs font-semibold">Masukkan Kode Booking Untuk Check-in</div>
            <Input placeholder="Eg.  BQ1A B514 3828 3C3D" bordered={false} className="!px-0" onChange={(e) => setBookingCode(e.target.value)} />
          </div>
          <Button type="primary" className="flex-1" onClick={onInputBookingCode}>Cari</Button>
        </div>
      </div>
      <div id="right-container" className="landing_bg_circle w-1/2 h-full bg-[#F9FAFB] rounded-l-3xl flex items-center">
        <div className="w-full">
          <div className="text-[#00529C] text-2xl font-extrabold text-center mb-3">Choose Services</div>
          <div className="text-[#33A0FF] text-center mb-11">
            Choose the service that you will reserve
          </div>
          <CardServiceOption
            title="Financial"
            detail="Financial Services"
            bgClassName="card_bg_dark_circle bg-[#00529C]"
            textClassName="text-[#00529C]"
            image={FinancialCardImg}
            onClick={() => onClickCard('financial')}
          />
          <CardServiceOption
            title="Non Financial"
            detail="Non Financial Services"
            bgClassName="card_bg_light_circle bg-[#33A0FF]"
            textClassName="text-[#33A0FF]"
            image={NonFinancialCardImg}
            onClick={() => onClickCard('non-financial')}
          />
        </div>
      </div>
    </div>
  )
}

export default Landing;