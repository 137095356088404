import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { App, Button, Divider, QRCode, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { queueApi } from "../services/api.service";
import { getSessionStorage } from "../services/jwt.service";
import { CircleCheckIcon } from "../components/SvgIcon";
import QueueBanner from "../assets/images/queue-banner.png";
import { useReactToPrint } from "react-to-print";
import PrintThermalComponent from "../components/PrintThermalComponent";

function QueueResult() {
  const navigate = useNavigate();
  const queueRef = useRef();
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);
  const [queue, setQueue] = useState();
  const [isPrinting, setIsPrinting] = useState(false);
  const queueDetail = getSessionStorage("queue");

  useEffect(() => {
    const generateQueueNumber = async () => {
      try {
        setLoading(true);
        const response = await queueApi(queueDetail?.kode_booking);
        if (response?.status != 200) throw response?.message;

        setLoading(false);
        setQueue(response?.data?.que_number);
      } catch (error) {
        setLoading(false);
        message.error(error);
        console.log("Error on generateQueueNumber:", error);
      }
    }
    generateQueueNumber();
  }, [])

  const generateRefCode = (param) => {
    if (Array.isArray(queueDetail?.referenceCode)) {
      if (param?.qrCode) {
        const refCode = queueDetail?.referenceCode || [];
        return refCode?.join("|");
      }
      return queueDetail?.referenceCode?.[0];
    }
    return queueDetail?.referenceCode;
  }

  const componentToPrint = useReactToPrint({
    content: () => queueRef.current,
    onAfterPrint: () => setIsPrinting(true),
    onPrintError: () => setIsPrinting(false)
  });

  const onPrint = () => {
    if (!queue) {
      message.error("please wait until the queue number appears.");
      return;
    }
    componentToPrint();
  };

  return (
    <div className="h-screen flex items-center">
      <div id="left-container" className="landing_bg_circle relative w-1/2 h-full bg-[#F9FAFB] rounded-r-3xl flex">
        <div className="w-full h-1/2 flex flex-col justify-center items-center gap-4 mb-11">
          <CircleCheckIcon width={54} height={54} />
          <div className="text-[#212B36] text-2xl font-semibold">Reservasi Berhasil!</div>
          <div className="text-[#637381] text-sm">Anda dapat mencetak Antrian</div>
        </div>
        <div className="absolute bottom-0 w-full">
          <img src={QueueBanner} alt="" className="mx-auto" />
        </div>
      </div>
      <div id="right-container" className="w-1/2 flex flex-col items-center">
        <div ref={queueRef} className="flex flex-col items-center bg-[#F9FAFB] rounded-2xl py-8 mb-5">
          <div className="text-[#212B36] text-xl font-semibold">Nomor Antrian Anda</div>
          <div className="text-[#33A0FF] text-[64px] font-bold leading-none py-2">
            {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />} />}
            {queue}
          </div>
          <div className="max-w-[200px] text-[#637381] text-sm text-center mx-10">Mohon tunggu sampai nomor antrian Anda dipanggil</div>
          <div className="relative w-full flex items-center">
            <div className="absolute -left-[1px] w-[18px] h-9 bg-[#00417B] rounded-r-full"></div>
            <Divider dashed style={{ borderWidth: "2px 0 0", borderColor: "#999999" }} />
            <div className="absolute -right-[1px] w-[18px] h-9 bg-[#00417B] rounded-l-full"></div>
          </div>
          <div className="flex mb-4">
            <QRCode value={generateRefCode({ qrCode: true }) || "nodata"} bordered={false} />
          </div>
          <div className="text-center mb-4">
            <div className="text-[#637381] text-xs">Kode Reservasi</div>
            <div className="text-[#212B36] font-bold">{queueDetail?.kode_booking || "-"}</div>
          </div>
          <div className="text-center">
            <div className="text-[#637381] text-xs">No. Referensi</div>
            <div className="text-[#212B36] font-bold">{generateRefCode() || "-"}</div>
          </div>
        </div>
        <Button type="primary" size="large" className="min-w-[270px] mb-4" onClick={() => navigate('/landing')}>Selesai</Button>
        {!isPrinting &&
          <Button size="large" className={`min-w-[270px] ${!queue ? 'cursor-not-allowed' : ''}`} onClick={onPrint}>
            Cetak Antrian
          </Button>
        }
      </div>
      <div className="hidden">
        <PrintThermalComponent ref={queueRef} queue={queue} queueDetail={queueDetail} />
      </div>
    </div>
  )
}

export default QueueResult;