import { App } from "antd";

function AppContext({ children }) {
  return (
    <App>
      {children}
    </App>
  );
}

export default AppContext;