import { forwardRef } from "react";
import { QRCode } from "antd";
import dayjs from "dayjs";
import idLocale from 'dayjs/locale/id';
import BRILogo from "../assets/images/logo-bank-bri.png";

dayjs.locale(idLocale);

function PrintThermalComponent(props, ref) {
  const { queue, queueDetail } = props;

  const generateRefCode = (param) => {
    if (Array.isArray(queueDetail?.referenceCode)) {
      if (param?.qrCode) {
        const refCode = queueDetail?.referenceCode || [];
        return refCode?.join("|");
      }
      return queueDetail?.referenceCode?.[0];
    }
    return queueDetail?.referenceCode;
  }

  return (
    <div ref={ref}>
      <div className="queue-card">
        <div className="queue-logo">
          <img src={BRILogo} alt="" />
        </div>
        <div className="queue-branch">{queueDetail?.nama_kantor || "-"}</div>
        <div className="queue-address">{queueDetail?.alamat_kantor || "-"}</div>
        <div className="queue-title">Nomor Antrian Anda</div>
        <div className="queue-number">{queue}</div>
        <div className="queue-qrcode">
          <QRCode size={128} value={generateRefCode({ qrCode: true }) || "-"} bordered={false} />
        </div>
        <div style={{ textAlign: 'center', marginBottom: 8 }}>
          <div style={{ color: '#637381', fontSize: 12 }}>Kode Reservasi</div>
          <div style={{ color: '#212B36', fontSize: 14, fontWeight: 700 }}>{queueDetail?.kode_booking}</div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <div style={{ color: '#637381', fontSize: 12 }}>No. Referensi</div>
          <div style={{ color: '#212B36', fontSize: 14, fontWeight: 700 }}>{generateRefCode() || "-"}</div>
        </div>
        <div className="queue-tagline">Jika Nomor Antrian Anda Terlewat, Harap Mengambil Nomor Antrian Baru</div>
        <div className="queue-printdate">{dayjs().format('dddd, DD MMMM YYYY')}&nbsp;&nbsp;&nbsp;{dayjs().format('HH:mm:ss')}</div>
      </div>
      <style type="text/css" media="print">{`
        @page {
          size: 76mm 150mm;
          margin: 0;
          padding: 0;
        }
        html, body {
          height: 100vh;
          margin: 0 !important;
          overflow: hidden;
        }
        .queue-card {
          color: #212B36;
          background-color: #FFFFFF;
          border-radius: 16px;
          padding: 32px 0;
          margin-bottom: 20px;
          text-align: center;
        }
        .queue-logo {
          width: 200px;
          margin: 0 auto;
        }
        .queue-branch {
          font-size: 16px;
          font-weight: 600;
          max-width: 270px;
          margin: 8px auto;
        }
        .queue-address {
          font-size: 12px;
          font-weight: 600;
          max-width: 270px;
          margin: 0 auto;
        }
        .queue-title {
          font-size: 14px;
          font-weight: 600;
          margin-top: 8px;
        }
        .queue-number {
          color: #212B36;
          font-size: 48px;
          font-weight: 700;
          line-height: 1.2;
        }
        .queue-tagline {
          font-size: 10px;
          font-weight: 600;
          max-width: 270px;
          margin: 8px auto 0 auto;
        }
        .queue-printdate {
          font-size: 10px;
          font-weight: 600;
        }
        .queue-qrcode {
          display: grid;
          place-items: center;
          margin-bottom: 8px;
        }
      `}</style>
    </div>
  );
}

export default forwardRef(PrintThermalComponent);