import axios from "axios"

export const tokenApi = async () => {
  return await axios.post("qms/guest");
}

export const checkBookingApi = async (bookingCode) => {
  return await axios.get(`qms/bookings/${bookingCode}`);
}

export const submitBookingApi = async (request) => {
  return await axios.post(`qms/bookings/${request?.branchCode}`, request);
}

export const submitEformApi = async (request) => {
  return await axios.post("qms/store_ref", request);
}

export const checkInApi = async (request) => {
  return await axios.post(`qms/check_in/${request?.branchCode}`, request);
}

export const queueApi = async (bookingCode) => {
  return await axios.post(`qms/get_que/${bookingCode}`);
}