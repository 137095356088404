import { Fragment, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import BackgroundBase from "../components/BackgroundBase";

function Layout() {
  const observerRef = useRef(null);

  const virtualKeyboardObserver = () => {
    const overlayElmnt = document?.getElementById('virtualKeyboardChromeExtensionOverlayScrollExtend');
    const keyboardElmnt = document?.getElementById("virtualKeyboardChromeExtension");
    if (!keyboardElmnt && !overlayElmnt) return;

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          document.body.style.marginBottom = 0;
          if (overlayElmnt.offsetHeight === 312) {
            overlayElmnt.style.height = (overlayElmnt.offsetHeight - 24) + "px";
          }
          if (mutation?.target?.style?.display === "none") {
            overlayElmnt.style.display = "none";
          }
        }
      });
    });
    observer.observe(keyboardElmnt, { attributes: true });
    observerRef.current = observer;
  }

  useEffect(() => {
    window.addEventListener("focus", virtualKeyboardObserver);
    return () => {
      if (observerRef.current) observerRef.current.disconnect();
      window.removeEventListener("focus", virtualKeyboardObserver);
    };
  }, [])

  return (
    <Fragment>
      <BackgroundBase />
      <Outlet />
    </Fragment>
  )
}

export default Layout;